import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,[_c(VCard,{staticClass:"pa-4"},[_c(VCardTitle,[_vm._v(_vm._s(_vm.entity.title))]),_c(VCardText,[_c('CategoryTabLinks'),_c(VRow,{staticClass:"pl-3 pb-3"}),_c(VRow,[_c(VCol,{attrs:{"sm":"3"}},[_vm._v("Заголовок")]),_c(VCol,{attrs:{"sm":"9"}},[_vm._v(_vm._s(_vm.entity.title))])],1),_c(VRow,[_c(VCol,{attrs:{"sm":"3"}},[_vm._v("Код")]),_c(VCol,{attrs:{"sm":"9"}},[_vm._v(_vm._s(_vm.entity.code))])],1),_c(VRow,[_c(VCol,{attrs:{"sm":"3"}},[_vm._v("Родительская категория")]),_c(VCol,{attrs:{"sm":"9"}},[_vm._v(_vm._s(_vm.entity.parent ? _vm.entity.parent.title : '-'))])],1),_c(VRow,[_c(VCol,[_c('span',[_c('b',[_vm._v("Основная картинка")])])])],1),_c(VRow,[_c(VCol,{staticClass:"d-flex flex-column justify-space-between",attrs:{"xs":"12","sm":"6","md":"4","lg":"3"}},[(_vm.entity.picture)?_c(VImg,{attrs:{"src":_vm.imageSrc(_vm.entity.picture)}}):_vm._e()],1)],1),_c(VRow,[_c(VCol,{attrs:{"sm":"3"}},[_vm._v("Описание")]),_c(VCol,{attrs:{"sm":"9"},domProps:{"innerHTML":_vm._s(_vm.htmlFormatted(_vm.entity.description))}})],1),_c(VRow,[_c(VCol,{attrs:{"sm":"3"}},[_vm._v("Сортировка")]),_c(VCol,{attrs:{"sm":"9"}},[_vm._v(_vm._s(_vm.entity.position))])],1),_c(VRow,{staticClass:"form-group",attrs:{"form":""}},[_c(VCol,{attrs:{"sm":"3"}},[_vm._v("Активен")]),_c(VCol,{attrs:{"sm":"9"}},[_c('span',{class:`true-false-label ${_vm.getBadge(_vm.entity.enabled)}`},[_vm._v("  "+_vm._s(_vm.entity.enabled ? 'Да' : 'Нет')+"  ")])])],1)],1),_c(VCardActions,{staticClass:"pa-4 d-flex justify-space-between"},[_c('div',[_c(VBtn,{staticClass:"mr-3",attrs:{"color":"light"},on:{"click":_vm.goBack}},[_vm._v("К списку")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }